// @ts-nocheck
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { CacheProvider, type EmotionCache } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { FC, PropsWithChildren } from 'react';

import { createEmotionCache } from '@/tools/lib/emotion';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

type Props = PropsWithChildren<{
  user?: any;
  emotionCache?: EmotionCache;
}>;

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export const AppProviders: any = (props) => {
  const { children, user, emotionCache = clientSideEmotionCache } = props;

  return (
    <UserProvider>
      <CacheProvider value={emotionCache}>
        {/* Mui CssBaseline disabled in this example as tailwind provides its own */}
        {/* <CssBaseline /> */}
        <QueryClientProvider client={queryClient}>
          {children}
        </QueryClientProvider>
      </CacheProvider>
    </UserProvider>
  );
};

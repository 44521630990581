// @ts-nocheck
import type { EmotionCache } from '@emotion/cache';
import { Analytics } from '@vercel/analytics/react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';
import nextI18nextConfig from '../../next-i18next.config.mjs';
import { AppProviders } from '../AppProviders';

import '../styles/global.css';
import '../styles/base.css';
import '@fontsource-variable/inter';

export type InternalToolsProps = AppProps & {
  emotionCache?: EmotionCache;
};

const InternalToolsApp = (appProps: InternalToolsProps) => {
  const { Component, pageProps, emotionCache } = appProps;
  return (
    <AppProviders emotionCache={emotionCache}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Living Security | Login</title>
        <meta
          name="description"
          content="The Living Security Experience Platform"
        />
        <link rel="icon" href="images/favicon.ico" />
      </Head>
      <Script
        src="https://kit.fontawesome.com/aa0912705c.js"
        crossOrigin="anonymous"
      />
      <Component {...pageProps} />
      <Analytics />
    </AppProviders>
  );
};

// @ts-ignore
export default appWithTranslation(InternalToolsApp, {
  ...nextI18nextConfig,
});
